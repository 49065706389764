<template>
  <div class="container mb-4">
    <div class="main-forum-language-link mt-2 d-flex justify-content-end">
      <SelectButton v-model="language" :options="options" aria-labelledby="single"/>
    </div>
    <div v-if="language === 'ru'" class="main-forum-rus d-flex flex-column">
      <div class="main-forum-topic">
        <p class="fs-4 m-0 mt-3 text-center">ИНФОРМАЦИОННОЕ ПИСЬМО</p>
      </div>
      <div class="main-forum-requirements-link text-center mb-3">
        <a href="#" @click="openDialogPage">Технические требования к статье</a>
      </div>
      <div class="main-forum-description">
        <p class="fs-5 lh-base text-center">Университет Международного Бизнеса имени К. Сагадиева приглашает академическое
          и деловое сообщество принять участие в Международной научной конференции <strong>«Сагадиевские Чтения 2024 – Казахстан в период глобальных трансформаций: вызовы и возможности».</strong>
        </p><br><p><span style="font-weight: bold; font-style: italic;">Сагадиев Кенжегали Абенович</span> (1938–2020). <em>Ученый-экономист, выдающийся организатор высшей школы и науки, известный государственный и общественный деятель</em>.</p>
          <br><p class="fs-5 lh-base">В работе конференции примут участие ведущие зарубежные и отечественные деятели
                науки, образования, бизнеса.</p>

      </div>
      <div class="main-forum-place-time ms-5">
        <p><strong>Даты проведения:</strong> 9 -10 апреля 2024 г.</p>
        <p><strong>Формат проведения:</strong> офлайн</p>
        <p><strong>Рабочие языки конференции:</strong> казахский, русский, английский.</p>
        <p><strong>Место проведения:</strong> г. Алматы, пр. Абая 8а</p>
      </div>
      <div class="main-forum-info">
        <div class="main-forum-info-topics">
          <p class="fw-normal ms-5">
          <strong>Работа конференции запланирована по следующим направлениям:</strong>
            <br>1. Экономический рост: развитие регионов и макроэкономическая политика;
            <br>2. Современная наука и технологии в условиях трансформационных процессов;
            <br>3. Актуальные проблемы общества и личности в контексте глобальных вызовов;
            <br>4. Проблемы и перспективы бизнес-сообщества в эпоху цифровой трансформации.
            <br>5. Проблемы и перспективы обеспечения равного уровня благосостояния разным поколениям Казахстана – круглый стол.
          </p>
        </div>
        <div class="main-forum-info-deadline">
          <p><strong>Срок регистрации и подачи статей:</strong> с 10 февраля по 15 марта 2024 года.
            <br>Для подачи заявки на участие автору необходимо перейти по ссылке <router-link to="/conference/conference-author">https://front.uib.kz/</router-link>, сначала зарегистрироваться во фронте, а затем зарегистрироваться на конференцию.
            <br>Планируется издание сборника материалов. Участие для всех бесплатное.
            <br>Загруженные в систему статьи проходят проверку на плагиат (не менее 80% уникальности, КП1 не должен превышать 20%, КП2 не более 15%) и двойное анонимное рецензирование.
            <br>Лучшие статьи на английском языке будут рекомендованы к публикации в журнале <strong>Eurasian Journal of Economic and Business Studies (EJEBS).</strong></p>
        </div>
        <div class="main-forum-info-contacts">
          <p>Контакты для дополнительной информации:
          <br>Атейбекова Аружан Асхатқызы - тел. 8(727)259-80-33,; 8 705 225 43 22; e-mail:<a href="mailto:atibekova.a@uib.kz">atibekova.a@uib.kz</a></p>
        </div>
      </div>
      <Dialog header="ТРЕБОВАНИЯ К ОФОРМЛЕНИЮ СТАТЕЙ"
              v-model:visible="isDialogPage"
              :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="true">
        <p class="fs-4 text-center">Структура статьи и критерии рецензирования:</p>
        <p>&emsp;&emsp;Оргкомитет конференции нацелен на соответствие высоким международным стандартам, в
          связи с чем статьи должны отражать результаты оригинальных теоретических, методических и
          прикладных научных исследований.
          <br>&emsp;&emsp;Приводим основные общепринятые элементы научной статьи, которые являются
          основными критериями для оценивания статьи при прохождении двойного анонимного
          рецензирования: <strong>введение, цель и задачи исследования, обзор литературы, методика
          исследования, полученные результаты и их обсуждение, вклад результатов исследования в
          науку, бизнес или общество, выводы.</strong></p>
        <p class="fs-4 text-center">Технические требования к статье.</p>
        <ul>
          <li>Предоставляемые статьи должны являться оригинальными неопубликованными ранее в других печатных или электронных изданиях.</li>
          <li>Загружаемый в систему файл со статьей должен быть представлен в формате Microsoft Word (иметь расширение *.doc, *.docx).</li>
          <li>Статья может быть на казахском, русском или английском языке.</li>
          <li>Название статьи: прописными буквами полужирным шрифтом, выравнивание по центру.</li>
          <li>Примечание: ФИО и аффилиации автора (-ов) не указывать в тексте статьи. Эта информация об авторе (-ах) фиксируется при регистрации.</li>
          <li>Объем статьи – 3500 и более слов, включая название статьи, абстракт (250-300 слов), ключевые слова (5-7 слов), текст статьи и список литературы.</li>
          <li>Шрифт – TNR 12; интервал 1,5; абзацный отступ – 0,75. Поля: верхнее и нижнее – 20 мм, левое – 30 мм, правое – 15 мм.</li>
          <li>В тексте статьи ссылки оформляются по правилам ГОСТ (если статьи на русском и казахском языках) или APA -8 style (если статьи на английском языке).</li>
          <li>Список литературы (не менее 20) помещается в конце текста и отделяется пустой строкой. Источники в списке указываются по мере цитирования в основном тексте если по ГОСТу и в алфавитном порядке если по APA.</li>
          <li>Примечание: список литературы загружается в систему отдельным файлом (не в тексте статьи)</li>
          <li>Номер и название рисунка размещают под рисунком, выравнивание – по центру. К рисункам относятся также схемы, фотографии, сканированные изображения и т.д.</li>
          <li>Номер и название таблицы указываются над таблицами (выравнивание – по центру).</li>
          <li>Таблицы, схемы, рисунки, формулы, графики не должны выходить за пределы указанных полей. Шрифт в таблицах и на рисунках – 10 pt.</li>
          <li>Кавычки обозначаются знаком «».</li>
          <li>При указании дат: Век обозначается римскими цифрами, Годы – арабскими.</li>
          <li>Не допускаются ручные переносы (manual hyphenation).</li>
        </ul>
        <p class="fs-4 text-center">Пример оформления списка использованных источников</p>
        <p class="fw-normal lh-base">&emsp;1 Агафонова Н.Н. Гражданское право: учебное пособие для вузов / под ред. А.Г. Калпина. – Изд. 2-е, перер. и доп. – М.: Юрист, 2003. – 542 с.
          <br>&emsp;2 Портер М.Е. Международная конкуренция / пер. с англ.; под ред. В.Д. Щепина. – М.: Международные отношения, 1993. – 140 с.
          <br>&emsp;3 Пат. 5773 РК. Способ извлечения золота из синтетических ионитов /Г.А. Нечистых; опубл. 30.03.98, Бюл. № 6.- 4 с.
          <br>&emsp;5 Vachaspati Т., Vidеnkin A. Evolution of cosmic nets //Phys.Rev.-2003.- Vol.46, №2. -P.1133-1140.
          <br>&emsp;6 Указ Президента Республики Казахстан. О некоторых вопросах развития агропромышленного комплекса: утв. 11 декабря 2006 года, № 220.
          <br>&emsp;7 Стратегический план развития Республики Казахстан до 2010 года: утв. Указом Президента Республики Казахстан от 4 декабря 2001 года, № 735 // www.minplan.kz. 28.12.2001.
          <br>&emsp;8 Байтова А. Инновационно-технологическое развитие – ключевой фактор повышения конкурентоспособности // Казахстанская правда. – 2009. – № 269.</p>
      </Dialog>
    </div>
    <div v-else-if="language === 'en'" class="main-forum-eng d-flex flex-column">
      <div class="main-forum-topic">
        <p class="fs-4 m-0 mt-3 text-center">INFORMATION LETTER</p>
      </div>
      <div class="main-forum-requirements-link text-center mb-3">
        <a href="#" @click="openDialogPage">Technical requirements for the article</a>
      </div>
      <div class="main-forum-description">
        <p class="fs-5 lh-base text-center">K. Sagadiev University of International Business invites the academic and business community to participate in the International Scientific Conference
          <strong>“Sagadiev Readings 2024 – Kazakhstan in the period of global transformations: challenges and opportunities”.</strong></p>
          <br><p><span style="font-weight: bold; font-style: italic;">Sagadiev Kenzhegali Abenovich</span> (1938–2020). <em>He is an economist, an outstanding organizer of higher education and science, a well-known statesman and public figure</em>.</p>
        <br><p class="fs-5 lh-base">The conference will be attended by leading foreign and domestic figures of science, education, and business.</p>
      </div>
      <div class="main-forum-place-time ms-5">
        <p><strong>Dates:</strong> April 09-10, 2024</p>
        <p><strong>Format of the event:</strong> offline</p>
        <p><strong>Working languages of the conference:</strong> Kazakh, Russian, English.</p>
        <p><strong>Venue:</strong> Almaty, Abaya 8a Ave.</p>
      </div>
      <div class="main-forum-info">
        <div class="main-forum-info-topics">
          <p class="fw-normal ms-5">
          <strong>The work of the conference is planned in the following areas: </strong>
            <br>1. Economic growth: regional development and macroeconomic policy;
            <br>2. Modern science and technology in the context of transformational processes;
            <br>3. Current problems of society and personality in the context of global challenges;
            <br>4. Problems and prospects of the business community in the era of digital transformation.
            <br>5. Problems and prospects of ensuring an equal level of well–being for different generations of Kazakhstan - round table.
          </p>
        </div>
        <div class="main-forum-info-deadline">
          <p><strong> Deadline for registration and submission of articles:</strong> from February 10 to March 15, 2024.
            <br>To apply for participation, the author must click on the link <router-link to="/conference/conference-author">https://front.uib.kz/</router-link>, first register at the front, and then register for the conference.
            <br>It is planned to publish a collection of materials. Participation is free of charge.
            <br>The best reports will be presented as posters during the Forum.
            <br>Articles uploaded to the system are checked for plagiarism (at least 80% uniqueness, SC1 should not exceed 20%, SC2 should not exceed 15%) and will pass double-blind peer review.
            <br>The best articles in English will be recommended for publication in the <strong>Eurasian Journal of Economic and Business Studies (EJEBS).</strong></p>
        </div>
        <div class="main-forum-info-contacts">
          <p>Contact us for more information:
          <br>Ateibekova Aruzhan Askhatkyzy- tel. 8(727)259-80-33,8 705 225 43 22; email: <a href="mailto:atibekova.a@uib.kz">atibekova.a@uib.kz</a></p>
        </div>
      </div>
      <Dialog header="REQUIREMENTS FOR THE DESIGN OF ARTICLES"
              v-model:visible="isDialogPage"
              :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="true">
        <p class="fs-4 text-center">Article structure and review criteria:</p>
        <p>&emsp;&emsp;The organizing сommittee of the conference is aimed at meeting high international standards, and
          therefore articles should reflect the results of original theoretical, methodological and applied scientific
          research.
          <br>&emsp;&emsp;We present the main generally accepted elements of a scientific article, which are the main criteria
          for evaluating an article when passing a double anonymous review: <strong>of the study, literature review, research methodology, the results obtained and their discussion, the
            contribution of research results to science, business or society, conclusions.</strong></p>
        <p class="fs-4 text-center">Technical requirements for the article.</p>
        <ul>
          <li>Submitted articles must be original and unpublished previously in other printed or electronic publications.</li>
          <li>The article file uploaded to the system must be submitted in Microsoft Word format (have the extension *.doc, *.docx).</li>
          <li>The article can be in Kazakh, Russian or English.</li>
          <li>Title of the article: capital letters in bold, centered alignment.</li>
          <li>Note: The full name and affiliation of the author(s) should not be indicated in the text of the article. This information about the author(s) is recorded during registration.</li>
          <li>The volume of the article is 3500 or more words, including the title of the article, abstract (250-300 words), keywords (5-7 words), the text of the article and the list of references.</li>
          <li>Font – TNR 12; interval 1.5; paragraph indentation – 0.75. Margins: upper and lower – 20 mm, left – 30 mm, right – 15 mm.</li>
          <li>In the text of the article, links are drawn up according to the rules of GOST (if the articles are in Russian and Kazakh) or APA - 8 style (if the articles are in English).</li>
          <li>The list of references (at least 20) is placed at the end of the text and separated by an empty line. Sources in the list are indicated as they are quoted in the main text if according to GOST and in alphabetical order if according to APA.</li>
          <li>Note: the list of references is loaded into the system as a separate file (not in the text of the article)</li>
          <li>The number and name of the drawing are placed under the drawing, and the alignment is centered. Drawings also include diagrams, photographs, scanned images, etc.</li>
          <li>The number and name of the table are indicated above the tables (alignment is centered).</li>
          <li>Tables, diagrams, figures, formulas, and graphs should not go beyond the specified fields. The font in the tables and figures is 10 pt.</li>
          <li>Quotation marks are indicated by the «» sign.</li>
          <li>When specifying dates: The century is indicated by Roman numerals, and the Years are Arabic.</li>
          <li>Manual hyphenation is not allowed.</li>
        </ul>
        <p class="fs-4 text-center">Example of the design of the list of used sources</p>
        <p class="fw-normal lh-base">&emsp;1 Агафонова Н.Н. Гражданское право: учебное пособие для вузов / под ред. А.Г. Калпина. – Изд. 2-е, перер. и доп. – М.: Юрист, 2003. – 542 с.
          <br>&emsp;2 Портер М.Е. Международная конкуренция / пер. с англ.; под ред. В.Д. Щепина. – М.: Международные отношения, 1993. – 140 с.
          <br>&emsp;3 Пат. 5773 РК. Способ извлечения золота из синтетических ионитов /Г.А. Нечистых; опубл. 30.03.98, Бюл. № 6.- 4 с.
          <br>&emsp;5 Vachaspati Т., Vidеnkin A. Evolution of cosmic nets //Phys.Rev.-2003.- Vol.46, №2. -P.1133-1140.
          <br>&emsp;6 Указ Президента Республики Казахстан. О некоторых вопросах развития агропромышленного комплекса: утв. 11 декабря 2006 года, № 220.
          <br>&emsp;7 Стратегический план развития Республики Казахстан до 2010 года: утв. Указом Президента Республики Казахстан от 4 декабря 2001 года, № 735 // www.minplan.kz. 28.12.2001.
          <br>&emsp;8 Байтова А. Инновационно-технологическое развитие – ключевой фактор повышения конкурентоспособности // Казахстанская правда. – 2009. – № 269.</p>
      </Dialog>
    </div>
    <div v-else-if="language === 'kz'" class="main-forum-kaz d-flex flex-column">
      <div class="main-forum-topic">
        <p class="fs-4 m-0 mt-3 text-center">АҚПАРАТТЫҚ ХАТ</p>
      </div>
      <div class="main-forum-requirements-link text-center mb-3">
        <a href="#" @click="openDialogPage">Технические требования к статье</a>
      </div>
      <div class="main-forum-description">
        <p class="fs-5 lh-base text-center">Кенжеғали Сағадиев атындағы Халықаралық бизнес университеті академиялық және іскер қоғамдастықты <strong>«Сағадиев оқулары 2024 – Қазақстан жаһандық трансформация кезеңінде: сын-тегеуріндер мен мүмкіндіктер»</strong>
          Халықаралық ғылыми конференциясына қатысуға шақырады.</p>
          <br><p><span style="font-weight: bold; font-style: italic;">Сағадиев Кенжеғали Әбенұлы </span> (1938–2020). <em>Экономист ғалым, жоғары мектеп пен ғылымның көрнекті ұйымдастырушысы, белгілі мемлекет және қоғам қайраткері</em>.</p>
        <br><p class="fs-5 lh-base">Конференция жұмысына ғылым, білім, бизнестің жетекші шетелдік және отандық қайраткерлері қатысады.</p>
      </div>
      <div class="main-forum-place-time ms-5">
        <p><strong>Өтетін күндері:</strong> 2024 жылғы 09-10 сәуір</p>
        <p><strong>Өткізу форматы:</strong> офлайн</p>
        <p><strong>Конференцияның жұмыс тілдері:</strong> қазақ, орыс, ағылшын.</p>
        <p><strong>Өтетін орны:</strong> Алматы қ., Абай 8а даңғылы.</p>
      </div>
      <div class="main-forum-info">
        <div class="main-forum-info-topics">
          <p class="fw-normal ms-5">
            <strong>Конференция жұмысы келесі бағыттар бойынша жоспарланған:</strong>
            <br>1. Экономикалық өсу: Өңірлердің дамуы және макроэкономикалық саясат;
            <br>2. Трансформациялық процестер жағдайындағы заманауи ғылым мен технологиялар;
            <br>3. Жаһандық сын қатерлер контекстіндегі қоғам мен тұлғаның өзекті мәселелері;
            <br>4. Цифрлық трансформация дәуіріндегі бизнес-қоғамдастықтың мәселелері мен болашағы;
            <br>5. Қазақстанның түрлі ұрпақтарына әл – ауқаттың тең деңгейін қамтамасыз етудің мәселелері мен перспективалары-дөңгелек үстел.
          </p>
        </div>
        <div class="main-forum-info-deadline">
          <p><strong>Тіркелу және мақаланы тапсыру мерзімі:</strong> 2024 жылғы 10 ақпаннан 15 наурызға дейін.
            <br>Қатысуға өтінім беру үшін автор <router-link to="/conference/conference-author">https://front.uib.kz/</router-link> сілтемесі арқылы алдымен фронтқа тіркеліп, одан кейін конференцияға тіркелуі керек.
            <br>Материалдар жинағын шығару жоспарлануда. Барлығына қатысу тегін.
            <br>Жүйеге жүктелген мақалалар плагиатқа тексеруден өтеді (бірегейлігінің кемінде 80% - ы, КП1 20% - дан аспауы тиіс, К2 15% - дан аспауы тиіс) және қос анонимді рецензиядан өтеді.
            <br>Ағылшын тіліндегі ең жақсы мақалалар<strong>Eurasian Journal of Economic and Business Studies (EJEBS).</strong>журналында жариялауға ұсынылады.</p>
        </div>
        <div class="main-forum-info-contacts">
          <p>Қосымша ақпарат алу үшін байланыстар:
            <br>Атейбекова Аружан Асхатқызы - тел. 8(727)259-80-33, 8 705 225 43 22; email: <a href="mailto:atibekova.a@uib.kz">atibekova.a@uib.kz</a></p>
        </div>
      </div>
      <Dialog header="ТРЕБОВАНИЯ К ОФОРМЛЕНИЮ СТАТЕЙ"
              v-model:visible="isDialogPage"
              :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="true">
        <p class="fs-4 text-center">Структура статьи и критерии рецензирования:</p>
        <p>&emsp;&emsp;Оргкомитет конференции нацелен на соответствие высоким международным стандартам, в
          связи с чем статьи должны отражать результаты оригинальных теоретических, методических и
          прикладных научных исследований.
          <br>&emsp;&emsp;Приводим основные общепринятые элементы научной статьи, которые являются
          основными критериями для оценивания статьи при прохождении двойного анонимного
          рецензирования: <strong>введение, цель и задачи исследования, обзор литературы, методика
            исследования, полученные результаты и их обсуждение, вклад результатов исследования в
            науку, бизнес или общество, выводы.</strong></p>
        <p class="fs-4 text-center">Технические требования к статье.</p>
        <ul>
          <li>Предоставляемые статьи должны являться оригинальными неопубликованными ранее в других печатных или электронных изданиях.</li>
          <li>Загружаемый в систему файл со статьей должен быть представлен в формате Microsoft Word (иметь расширение *.doc, *.docx).</li>
          <li>Статья может быть на казахском, русском или английском языке.</li>
          <li>Название статьи: прописными буквами полужирным шрифтом, выравнивание по центру.</li>
          <li>Примечание: ФИО и аффилиации автора (-ов) не указывать в тексте статьи. Эта информация об авторе (-ах) фиксируется при регистрации.</li>
          <li>Объем статьи – 3500 и более слов, включая название статьи, абстракт (250-300 слов), ключевые слова (5-7 слов), текст статьи и список литературы.</li>
          <li>Шрифт – TNR 12; интервал 1,5; абзацный отступ – 0,75. Поля: верхнее и нижнее – 20 мм, левое – 30 мм, правое – 15 мм.</li>
          <li>В тексте статьи ссылки оформляются по правилам ГОСТ (если статьи на русском и казахском языках) или APA -8 style (если статьи на английском языке).</li>
          <li>Список литературы (не менее 20) помещается в конце текста и отделяется пустой строкой. Источники в списке указываются по мере цитирования в основном тексте если по ГОСТу и в алфавитном порядке если по APA.</li>
          <li>Примечание: список литературы загружается в систему отдельным файлом (не в тексте статьи)</li>
          <li>Номер и название рисунка размещают под рисунком, выравнивание – по центру. К рисункам относятся также схемы, фотографии, сканированные изображения и т.д.</li>
          <li>Номер и название таблицы указываются над таблицами (выравнивание – по центру).</li>
          <li>Таблицы, схемы, рисунки, формулы, графики не должны выходить за пределы указанных полей. Шрифт в таблицах и на рисунках – 10 pt.</li>
          <li>Кавычки обозначаются знаком «».</li>
          <li>При указании дат: Век обозначается римскими цифрами, Годы – арабскими.</li>
          <li>Не допускаются ручные переносы (manual hyphenation). Пример оформления списка использованных источников</li>
        </ul>
        <p class="fs-4 text-center">Пример оформления списка использованных источников</p>
        <p class="fw-normal lh-base">&emsp;1 Агафонова Н.Н. Гражданское право: учебное пособие для вузов / под ред. А.Г. Калпина. – Изд. 2-е, перер. и доп. – М.: Юрист, 2003. – 542 с.
          <br>&emsp;2 Портер М.Е. Международная конкуренция / пер. с англ.; под ред. В.Д. Щепина. – М.: Международные отношения, 1993. – 140 с.
          <br>&emsp;3 Пат. 5773 РК. Способ извлечения золота из синтетических ионитов /Г.А. Нечистых; опубл. 30.03.98, Бюл. № 6.- 4 с.
          <br>&emsp;5 Vachaspati Т., Vidеnkin A. Evolution of cosmic nets //Phys.Rev.-2003.- Vol.46, №2. -P.1133-1140.
          <br>&emsp;6 Указ Президента Республики Казахстан. О некоторых вопросах развития агропромышленного комплекса: утв. 11 декабря 2006 года, № 220.
          <br>&emsp;7 Стратегический план развития Республики Казахстан до 2010 года: утв. Указом Президента Республики Казахстан от 4 декабря 2001 года, № 735 // www.minplan.kz. 28.12.2001.
          <br>&emsp;8 Байтова А. Инновационно-технологическое развитие – ключевой фактор повышения конкурентоспособности // Казахстанская правда. – 2009. – № 269.</p>
      </Dialog>
    </div>
  </div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';

export default {
  name: "Forum2023",
  data() {
    return {
      isDialogPage: false,
      language: 'ru',
      options: ['ru', 'kz', 'en']
    }
  },
  computed: {

  },

  methods: {
    openDialogPage(){
      this.isDialogPage = true
    }
  },

  async mounted() {

  }
}
</script>

<style scoped>
</style>
